/* eslint-disable max-len */

import {Controller} from 'stimulus';
import L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-ajax';
import Papa from 'papaparse';
import debounce from '../helpers/debounce';
import 'leaflet-spin';

const ACCESS_TOKEN = 'pk.eyJ1IjoiYWFyb25tYXJ6YW4iLCJhIjoiY2syZDM5ODU2MDdlaDNob2RnNnF0Zjc3aiJ9.9FfCGfEB3fsoBuH0JFQSFA';

const MAP_CENTER = [29.920489, -96.586474];
const CLUSTER_ZOOM_LEVEL = 9;
const BLUE = '#1a237e';
const GREEN = '#06B777';
const WHITE = '#000000';

export default class extends Controller {
  static targets = ['placeholder', 'zipcodesList', 'zipcodeInput', 'fileInput', 'hiddenInput', 'submitButton'];
  static values = {selectedZipcodes: String};

  async connect() {
    this.postalCodesToLayers = {};

    const map = await this._initMap();
    this.polygonLayer = L.layerGroup();
    const markerLayer = this._createMarkerLayer();
    this.selectedZipcodes = new Set();

    this._setupLayers(map, markerLayer);

    await this._loadZipcodes('TX', map, markerLayer);

    this.initSelectedZipcodes();
  }

  initSelectedZipcodes() {
    const postalCodes = this.selectedZipcodesValue.split(',').filter(String);

    this.selectedZipcodes = new Set(postalCodes);
    this.renderSelectedZipcodes();
  }

  async _initMap() {
    const renderer = L.svg({padding: 2});
    const map = L.map(this.placeholderTarget.id, {renderer: renderer});
    const mapboxStyleUrl = 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}';

    map.setView(MAP_CENTER, CLUSTER_ZOOM_LEVEL - 1);

    L.tileLayer(mapboxStyleUrl, {
      maxZoom: 18,
      id: 'mapbox/streets-v11',
      accessToken: ACCESS_TOKEN,
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    this._initLegend(map);

    return map;
  }

  _initLegend(map) {
    const legend = L.control({position: 'bottomleft'});

    legend.onAdd = function(map) {
      const div = L.DomUtil.create('div', 'info legend');
      div.innerHTML += `<i style="background:${GREEN}"></i> Available zip codes<br><i style="background:${BLUE}"></i> Chosen zip codes<br>`;
      return div;
    };
    legend.addTo(map);
  }

  _setupLayers(map, markerLayer) {
    this.previousZoomLevelWasPolygon = false;

    map.on('zoomend', () => {
      if (map.getZoom() > CLUSTER_ZOOM_LEVEL) {
        this._safeFetchPolygons(map);

        if (map.hasLayer(markerLayer)) {
          map.removeLayer(markerLayer);
        }

        this.previousZoomLevelWasPolygon = true;
      } else {
        if (this.previousZoomLevelWasPolygon) {
          markerLayer.clearLayers();
          this._fetchMarkers('TX', map, markerLayer);
        }
        map.addLayer(markerLayer);

        if (map.hasLayer(this.polygonLayer)) {
          map.removeLayer(this.polygonLayer);
        }

        this.previousZoomLevelWasPolygon = false;
      }
    });

    const shouldFetchPolygonsAfterDrag = (map, distance) => {
      // we just naively count pixels dragged (disregarding direction)
      // and when those multiple drag events sums to more than 3/4 of a smaller
      // of [mapHeight, mapWidth] we issue a new request
      //
      // the problem with this naive approach is that if someone drags back
      // and forth the sum will exceed the min distance for fetch but the map
      // will be still in the same place - it is not a big problem and a cheap
      // and kinda good workaround to not reload everytime user drags, keeping
      // in mind that we have renderer padding set to 3
      this.dragDistanceInPixels += distance;

      const minDistance = Math.min.apply(null, Object.values(map.getSize()));
      const isDistanceProper = this.dragDistanceInPixels > minDistance;
      const isZoomProper = map.getZoom() > CLUSTER_ZOOM_LEVEL;

      return isZoomProper && isDistanceProper;
    };

    map.on('dragend', (event) => {
      if (shouldFetchPolygonsAfterDrag(map, event.distance)) {
        this._safeFetchPolygons(map);
      }
    });
  }

  _safeFetchPolygons(map) {
    if (!this._debouncedFetchPolygons) {
      this._debouncedFetchPolygons = debounce(this._fetchPolygons.bind(this), 300);
    }

    this._debouncedFetchPolygons(map);
  }

  async _loadZipcodes(state, map, markerLayer) {
    map.addLayer(markerLayer);
    this._fetchMarkers(state, map, markerLayer);
  }

  async _fetchMarkers(state, map, markerLayer) {
    const url = '/api/v1/zipcodes?state=' + state;

    L.geoJSON.ajax(url, {
      pointToLayer: (point) => {
        L.marker(point.geometry.coordinates, point.properties).addTo(markerLayer);
      },
    }).addTo(map);
  }

  _createMarkerLayer() {
    return L.markerClusterGroup({
      zoomToBoundsOnClick: true,
      spiderfyOnMaxZoom: false,
      showCoverageOnHover: false,
      maxClusterRadius: 120,
      singleMarkerMode: true,

      iconCreateFunction: (cluster) => {
        const markers = cluster.getAllChildMarkers();
        let selectedCnt = 0;

        markers.forEach((marker) => {
          if (this.selectedZipcodes.has(marker.options.postal_code)) {
            selectedCnt += 1;
          }
        });
        const unselectedCnt = markers.length - selectedCnt;
        const unselectedHtml = unselectedCnt > 0 ? `<div class="unselected-count">${unselectedCnt}</div>` : '';
        const selectedHtml = selectedCnt > 0 ? `<div class="selected-count">&#10003; ${selectedCnt}</div>` : '';

        const html = `
                <span style="white-space: nowrap;">
                  ${unselectedHtml} ${selectedHtml}
                </span>
              `;

        const classNames = ['cluster-label'];

        if (unselectedCnt > 0) {
          classNames.push('has-unselected');
        } else if (selectedCnt > 0) {
          classNames.push('all-selected');
        }

        return L.divIcon({
          html: html,
          className: classNames.join(' '),
          iconSize: L.point(),
        });
      },
    });
  }

  markZipcodeSelected(postalCode) {
    if (!postalCode) return null;

    this.selectedZipcodes.add(postalCode);
    this.renderSelectedZipcodes();
  }

  markMultipleZipcodesSelected(postalCodes) {
    postalCodes.forEach((postalCode) => this.selectedZipcodes.add(postalCode));
    this.renderSelectedZipcodes();
  }

  markZipcodeUnselected(postalCode) {
    this.selectedZipcodes.delete(postalCode);
    this.renderSelectedZipcodes();
  }

  toggleZipcodeSelection(postalCode) {
    if (this.selectedZipcodes.has(postalCode)) {
      this.selectedZipcodes.delete(postalCode);
    } else {
      this.selectedZipcodes.add(postalCode);
    }
    this.renderSelectedZipcodes();
  }

  renderSelectedZipcodes() {
    this.renderZipcodesList();
    this.renderPolygonStyles();
    this.updateSelectedZipcodesHiddenInput();
  }

  updateSelectedZipcodesHiddenInput() {
    const postalCodes = Array.from(this.selectedZipcodes.values());
    this.hiddenInputTarget.value = postalCodes.join(',');

    if (postalCodes.length > 0) {
      this.submitButtonTarget.classList.add('btn-success');
      this.submitButtonTarget.classList.remove('btn-secondary');
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.classList.add('btn-secondary');
      this.submitButtonTarget.classList.remove('btn-success');
      this.submitButtonTarget.disabled = true;
    }
  }

  renderPolygonStyles() {
    Object.entries(this.postalCodesToLayers).forEach(([postalCode, layer]) => {
      layer.setStyle(this.getStyleForFeature(layer.feature));
    });
  }

  renderZipcodesList() {
    const zipcodes = Array.from(this.selectedZipcodes.values());

    const html = zipcodes.map((zipcode) => {
      return `<li><span class="badge">${zipcode}<a id=${zipcode} data-action="click->map#removeZipcode" class="close">×</a></span></li>`;
    });

    this.zipcodesListTarget.innerHTML = html.join('');
  }

  getStyleForFeature(feature) {
    const postalCode = feature.properties.postal_code;
    const isSelected = this.selectedZipcodes.has(postalCode);
    console.log('getStyleForFeature: postalCode,isSelected', postalCode, isSelected);

    const style = {
      color: BLUE,
      fillColor: isSelected ? BLUE : WHITE,
      fillOpacity: isSelected ? '0.25' : '0',
    };

    if (feature.geometry.type === 'Point') {
      return {...style, radius: 10};
    } else {
      return style;
    }
  }

  removeZipcode({target: {id: postalCode}}) {
    this.markZipcodeUnselected(postalCode);
  }

  async _fetchPolygons(map) {
    console.log('_fetchPolygons: postalCodesToLayers', this.postalCodesToLayers);
    const n = map.getBounds().getNorth();
    const s = map.getBounds().getSouth();
    const e = map.getBounds().getEast();
    const w = map.getBounds().getWest();

    // reset distance traveled (in pixels)
    this.dragDistanceInPixels = 0;

    // keep track of previous layer
    const previousPolygonLayer = this.polygonLayer;
    const newPolygonLayer = L.layerGroup();

    const url = `/api/v1/zipcodes/shapes?n=${n}&s=${s}&w=${w}&e=${e}`;
    const options = {
      onEachFeature: (_, layer) => {
        console.log('onEachFeature: layer', layer);

        this.postalCodesToLayers[layer.feature.properties.postal_code] = layer;

        layer.on('click', ({target: aLayer}) => {
          const postalCode = aLayer.feature.properties.postal_code;

          this.toggleZipcodeSelection(postalCode);
        });
      },

      style: (feature) => {
        console.log('style: feature', feature);

        return this.getStyleForFeature(feature);
      },

      pointToLayer: (feature, latlng) => {
        console.log('pointToLayer: feature, latlng', feature, latlng);

        const centerLatitude = feature.properties.center_latitude;
        const centerLongitude = feature.properties.center_longitude;

        const ll = L.latLng(centerLatitude, centerLongitude);

        const circleMarker = L.circleMarker(ll, this.getStyleForFeature(feature));

        console.log('pointToLayer: circleMarker', circleMarker);
        return circleMarker;
      },
    };

    const onDataLoaded = (...params) => {
      console.log('data:loaded', params);
      if (previousPolygonLayer) {
        previousPolygonLayer.clearLayers();
        map.removeLayer(previousPolygonLayer);
      }
    };

    // fetch polygons to a new layer
    L.geoJSON
        .ajax(url, options)
        .on('data:loading', (...params) => {
          console.log('data:loading', params);
        })
        .on('data:progress', (...params) => {
          console.log('data:progress', params);
        })
        .on('data:loaded', onDataLoaded)
        .addTo(newPolygonLayer);
    console.log('_fetchPolygons: after ajax call');
    map.addLayer(newPolygonLayer);

    this.polygonLayer = newPolygonLayer;
  }

  ensureZipcodesAreSelected(event) {
    if (Array.from(this.selectedZipcodes.values()).length === 0) {
      alert('Select some zipcodes first!');
      event.preventDefault();
      event.stopPropagation();
    }
  }

  handlePasteZipcodes(event) {
    const pastedText = (event.clipboardData || window.clipboardData).getData('text');
    const words = pastedText.split(/[ ,.;]+/);

    this.markMultipleZipcodesSelected(words);

    event.preventDefault();
  }

  handleAddZipcodeInputKeydown(event) {
    const delimiters = ['Enter', 'Space', 'Comma', 'Semicolon', 'Tab'];

    const keycode = event.code;
    const postalCode = event.target.value;

    if (delimiters.indexOf(keycode) > -1) {
      this.markZipcodeSelected(postalCode);
      this.zipcodeInputTarget.value = '';
      this.zipcodeInputTarget.focus();
      event.stopPropagation();
      event.preventDefault();
    }
  }

  readAndParseCsv() {
    const csv = this.fileInputTarget.files[0];
    Papa.parse(csv, {
      header: true,
      complete: ({data: rows}) => {
        const postalCodes = rows.map(({zipcode: postalCode}) => postalCode);
        const validPostalCodes = postalCodes.filter((postalCode) => {
          if (/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(postalCode)) {
            return postalCode;
          }
        });

        this.markMultipleZipcodesSelected(validPostalCodes);

        // reset file input so we can upload again
        this.fileInputTarget.value = null;
      },
    });
  }
}
