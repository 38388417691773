import {Controller} from 'stimulus';
import Headroom from 'headroom.js';

export default class extends Controller {
  static targets = ['elem']

  connect() {
    this.headroom = new Headroom(this.elemTarget, {
      'tolerance': 0,
    });

    this.headroom.init();
  }

  disconnect() {
    this.headroom.destroy();
  }
}
