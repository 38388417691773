import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['container'];

  close(evt) {
    evt.preventDefault();
    this.containerTarget.remove();
  }
}
