import {Controller} from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['widget']
  static values = {name: String, email: String, uuid: String, url: String}

  connect() {
    this.boundSaveMeetingScheduled = this.saveMeetingScheduled.bind(this);
    window.addEventListener('message', this.boundSaveMeetingScheduled);

    Calendly.initInlineWidget({
      url: this.urlValue,
      parentElement: this.widgetTarget,
      prefill: {
        name: this.nameValue,
        email: this.emailValue,
      },
    });
  }

  disconnect() {
    window.removeEventListener('message', this.boundSaveMeetingScheduled);
  }

  saveMeetingScheduled(event) {
    if (event.data.event !== 'calendly.event_scheduled') return null;

    const lead = {
      event: event.data.payload.event.uri,
      invitee: event.data.payload.invitee.uri,
    };

    const data = new FormData();
    data.append('event_url', lead.event);
    data.append('invitee_url', lead.invitee);

    fetch(`${this.uuidValue}/schedule_meeting`, {
      method: 'PATCH',
      body: data,
      credentials: 'same-origin',
      headers: {'X-CSRF_Token': Rails.csrfToken()},
    });
  }
}
