/* eslint-disable max-len */

import {Controller} from 'stimulus';
export default class extends Controller {
  copy(event) {
    const text = event.target.dataset.text;

    navigator.clipboard.writeText(text);

    this.element.innerText = 'copied!';
  }
}
